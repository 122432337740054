import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { SelectionGroup, Link } from 'hds-react';
import ExternalLink from '../../../components/ExternalLink';
import Playground from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/components/checkbox/code",
  "title": "Checkbox - Code"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const IconCheckCircleFill = makeShortcode("IconCheckCircleFill");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "code",
      "style": {
        "position": "relative"
      }
    }}>{`Code`}<a parentName="h2" {...{
        "href": "#code",
        "aria-label": "code permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "code-examples",
      "style": {
        "position": "relative"
      }
    }}>{`Code examples`}<a parentName="h3" {...{
        "href": "#code-examples",
        "aria-label": "code examples permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "checkbox-with-label",
      "style": {
        "position": "relative"
      }
    }}>{`Checkbox with label`}<a parentName="h4" {...{
        "href": "#checkbox-with-label",
        "aria-label": "checkbox with label permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`{() => {
  const [checkedItems, setCheckedItems] = React.useState({ "checkbox-checked": true, "checkbox-checked-disabled": true });
  const onChange = (event) => {
    setCheckedItems({ ...checkedItems, [event.target.id]: event.target.checked });
  };
  return <>
    <Checkbox label="Label" id="checkbox" checked={checkedItems["checkbox"]} onChange={onChange} />
    <Checkbox label="Label" id="checkbox-checked" checked={checkedItems["checkbox-checked"]} onChange={onChange} />
    <Checkbox label="Label" id="checkbox-disabled" checked={checkedItems["checkbox-disabled"]} onChange={onChange} disabled />
    <Checkbox label="Label" id="checkbox-checked-disabled" checked={checkedItems["checkbox-checked-disabled"]} onChange={onChange} disabled />
  </>
}}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<div>
  <div class="hds-checkbox">
    <input type="checkbox" id="checkbox-core" class="hds-checkbox__input" />
    <label for="checkbox-core"  class="hds-checkbox__label">Label</label>
  </div>

  <div class="hds-checkbox">
    <input type="checkbox" id="checkbox-checked-core" class="hds-checkbox__input" checked />
    <label for="checkbox-checked-core"  class="hds-checkbox__label">Label</label>
  </div>

  <div class="hds-checkbox">
    <input type="checkbox" id="checkbox-disabled-core" class="hds-checkbox__input" disabled />
    <label for="checkbox-disabled-core"  class="hds-checkbox__label">Label</label>
  </div>

  <div class="hds-checkbox">
    <input type="checkbox" id="checkbox-checked-disabled" class="hds-checkbox__input" checked disabled />
    <label for="checkbox-checked-disabled"  class="hds-checkbox__label">Label</label>
  </div>
</div>
`}</code></pre>
    </Playground>
    <h4 {...{
      "id": "checkbox-group",
      "style": {
        "position": "relative"
      }
    }}>{`Checkbox group`}<a parentName="h4" {...{
        "href": "#checkbox-group",
        "aria-label": "checkbox group permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`{() => {
  const [checkedItems, setCheckedItems] = React.useState({ "v-checkbox1": true, "v-checkbox2": true });
  const onChange = (event) => {
    setCheckedItems({ ...checkedItems, [event.target.name]: event.target.checked });
  };
  return (
    <SelectionGroup label="Checkbox group">
      <Checkbox id="v-checkbox1" name="v-checkbox1" label="Option" checked={checkedItems["v-checkbox1"]} onChange={onChange} />
      <Checkbox id="v-checkbox2" name="v-checkbox2" label="Option" checked={checkedItems["v-checkbox2"]} onChange={onChange} />
      <Checkbox id="v-checkbox3" name="v-checkbox3" label="Option" checked={checkedItems["v-checkbox3"]} onChange={onChange} />
      <Checkbox id="v-checkbox4" name="v-checkbox4" label="Option" checked={checkedItems["v-checkbox4"]} onChange={onChange} />
    </SelectionGroup>
  );
}}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<fieldset class="hds-selection-group">
  <legend class="hds-selection-group__legend">Checkbox group</legend>
  <div class="hds-selection-group__items">
    <div class="hds-selection-group__item">
      <div class="hds-checkbox">
        <input type="checkbox" id="v-checkbox1" name="v-checkbox1" class="hds-checkbox__input" value="foo" checked />
        <label for="v-checkbox1" class="hds-checkbox__label">Option</label>
      </div>
    </div>
    <div class="hds-selection-group__item">
      <div class="hds-checkbox">
        <input type="checkbox" id="v-checkbox2" name="v-checkbox2" class="hds-checkbox__input" value="bar" checked/>
        <label for="v-checkbox2" class="hds-checkbox__label">Option</label>
      </div>
    </div>
    <div class="hds-selection-group__item">
      <div class="hds-checkbox">
        <input type="checkbox" id="v-checkbox3" name="v-checkbox3" class="hds-checkbox__input" value="baz" />
        <label for="v-checkbox3" class="hds-checkbox__label">Option</label>
      </div>
    </div>
    <div class="hds-selection-group__item">
      <div class="hds-checkbox">
        <input type="checkbox" id="v-checkbox4" name="v-checkbox4" class="hds-checkbox__input" value="qux" />
        <label for="v-checkbox4" class="hds-checkbox__label">Option</label>
      </div>
    </div>
  </div>
</fieldset>
`}</code></pre>
    </Playground>
    <h4 {...{
      "id": "indeterminate",
      "style": {
        "position": "relative"
      }
    }}>{`Indeterminate`}<a parentName="h4" {...{
        "href": "#indeterminate",
        "aria-label": "indeterminate permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`{() => {
  const CheckboxState = {
    checked: 0,
    unchecked: 1,
    indeterminate: 2,
  };
  const areAllChecked = (state) => {
    let checkedCount = 0;
    Object.keys(state).forEach((key) => {
      if (key === 'controllerCheckbox') {
        return;
      }
      if (state[key] === CheckboxState.checked) {
        checkedCount += 1;
      }
    });
    return checkedCount === 4;
  };
  const areAllUnchecked = (state) => {
    let checkedCount = 0;
    Object.keys(state).forEach((key) => {
      if (key === 'controllerCheckbox') {
        return;
      }
      if (state[key] === CheckboxState.checked) {
        checkedCount += 1;
      }
    });
    return checkedCount === 1;
  };
  const reducer = (state, action) => {
    switch (action.type) {
      case 'check': {
        if (action.payload === 'controllerCheckbox') {
          return {
            controllerCheckbox: CheckboxState.checked,
            checkbox1: CheckboxState.checked,
            checkbox2: CheckboxState.checked,
            checkbox3: CheckboxState.checked,
            checkbox4: CheckboxState.checked,
            checkbox5: CheckboxState.checked,
          };
        }
        return {
          ...state,
          [action.payload]: CheckboxState.checked,
          controllerCheckbox: areAllChecked(state) ? CheckboxState.checked : CheckboxState.indeterminate,
        };
      }
      case 'uncheck': {
        if (action.payload === 'controllerCheckbox') {
          return {
            controllerCheckbox: CheckboxState.unchecked,
            checkbox1: CheckboxState.unchecked,
            checkbox2: CheckboxState.unchecked,
            checkbox3: CheckboxState.unchecked,
            checkbox4: CheckboxState.unchecked,
            checkbox5: CheckboxState.unchecked,
          };
        }
        return {
          ...state,
          [action.payload]: CheckboxState.unchecked,
          controllerCheckbox: areAllUnchecked(state) ? CheckboxState.unchecked : CheckboxState.indeterminate,
        };
      }
      default:
        throw new Error();
    }
  };
  const initialState = {
    controllerCheckbox: CheckboxState.indeterminate,
    checkbox1: CheckboxState.checked,
    checkbox2: CheckboxState.checked,
    checkbox3: CheckboxState.unchecked,
    checkbox4: CheckboxState.checked,
    checkbox5: CheckboxState.unchecked,
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <Fieldset heading="Group label *">
      <Checkbox
        aria-controls="checkbox1 checkbox2 checkbox3 checkbox4 checkbox5"
        id="controllerCheckbox"
        label="Label"
        indeterminate={state.controllerCheckbox === CheckboxState.indeterminate}
        checked={state.controllerCheckbox === CheckboxState.checked}
        style={{ marginTop: 'var(--spacing-xs)' }}
        onChange={() => {
          if (
            state.controllerCheckbox === CheckboxState.unchecked ||
            state.controllerCheckbox === CheckboxState.indeterminate
          ) {
            dispatch({ type: 'check', payload: 'controllerCheckbox' });
          } else {
            dispatch({ type: 'uncheck', payload: 'controllerCheckbox' });
          }
        }}
      />
      <ul style={{ marginLeft: 'var(--spacing-s)', paddingInlineStart: '0' }}>
        {Object.entries(state).map((entry) => {
          if (entry[0] === 'controllerCheckbox') {
            return null;
          }
          return (
            <li key={entry[0]} style={{ marginTop: 'var(--spacing-s)', listStyle: 'none' }}>
              <Checkbox
                id={entry[0]}
                key={entry[0]}
                label="Label"
                checked={entry[1] === CheckboxState.checked}
                onChange={() => {
                  if (entry[1] === CheckboxState.unchecked) {
                    dispatch({ type: 'check', payload: entry[0] });
                  } else {
                    dispatch({ type: 'uncheck', payload: entry[0] });
                  }
                }}
              />
            </li>
          );
        })}
      </ul>
    </Fieldset>
  );
}}
`}</code></pre>
    </Playground>
    <h3 {...{
      "id": "packages",
      "style": {
        "position": "relative"
      }
    }}>{`Packages`}<a parentName="h3" {...{
        "href": "#packages",
        "aria-label": "packages permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Package`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Included`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Storybook link`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Source link`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`HDS React`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><div style={{
              display: 'flex',
              gap: 'var(--spacing-3-xs)'
            }}><IconCheckCircleFill mdxType="IconCheckCircleFill" />{` Yes `}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}><Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/react/?path=/story/components-checkbox--default" mdxType="Link">{`View in Storybook`}</Link></td>
          <td parentName="tr" {...{
            "align": null
          }}><ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://github.com/City-of-Helsinki/helsinki-design-system/blob/master/packages/react/src/components/checkbox/Checkbox.tsx" mdxType="ExternalLink">{`View source`}</ExternalLink></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`HDS Core`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><div style={{
              display: 'flex',
              gap: 'var(--spacing-3-xs)'
            }}><IconCheckCircleFill mdxType="IconCheckCircleFill" />{` Yes `}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}><Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/core/?path=/story/components-checkbox--default" mdxType="Link">{`View in Storybook`}</Link></td>
          <td parentName="tr" {...{
            "align": null
          }}><ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://github.com/City-of-Helsinki/helsinki-design-system/tree/master/packages/core/src/components/checkbox" mdxType="ExternalLink">{`View source`}</ExternalLink></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "properties",
      "style": {
        "position": "relative"
      }
    }}>{`Properties`}<a parentName="h3" {...{
        "href": "#properties",
        "aria-label": "properties permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Note! You can find the full list of properties in the `}<Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/react/?path=/story/components-checkbox--default" mdxType="Link">{`React Storybook.`}</Link></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Values`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`checked`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to true, the component is checked.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to true, the component is disabled.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`indeterminate`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to true, the component is in indeterminate state.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A text label for the checkbox.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onChange`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Callback fired when the state is changed.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`function`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      